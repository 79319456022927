import { NotificationsService } from './notifications.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StorageService } from './storage.service';
import { AppSettings } from './../app.settings';
import { UserCommonService } from './user-common.service';

@Injectable()
export class AuthenticationValidationService implements CanActivate {
  userMeResponse: any;

  constructor(
    private storageService: StorageService,
    private router: Router,
    private notificationService: NotificationsService,
    private userCommonService: UserCommonService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.setTagList();
    const interval = +this.storageService.getItemLocalStorage(AppSettings.INTERVAL);
    if (interval && interval > 0) {
      return this.checkIfValidUser();
    }
    localStorage.setItem('returnUrl', state.url);
    this.router.navigate(['/signin']);
  }

  checkIfValidUser(): Promise<boolean> {
    return new Promise(res => {
      this.userCommonService.userMe().subscribe((result: any) => {
        if (result) {
          this.userMeResponse = result;
          this.storageService.setItem('headerAccessPermissionDispatch', result.addDispatch);
          this.storageService.setItem('headerAccessPermissionContactForm', result.addContactForm);
          if (this.userMeResponse.accessGroup !== null && this.userMeResponse.accessGroup.length > 0) {
            this.notificationService.canFetchNotification = true;
            this.setSessionAndUserDetails(result);
            if (this.userCommonService.isRolesAndGenerateSidebar === false) {
              this.userCommonService.setRolesAndGenerateSidebar();
            }
          }
          res(true);
          return true;
        } else {
          this.notificationService.canFetchNotification = false;
          res(false);
          this.router.navigate(['/signin']);
          return false;
        }
      }, (err) => {
        this.notificationService.canFetchNotification = false;
        this.router.navigate(['/signin']);
        res(false);
        return false;
      });
    });
  }

  setSessionAndUserDetails(results) {
    this.storageService.setItem(AppSettings.USER_DETAILS, results.user);
    this.storageService.setItem(AppSettings.ACCESS_MENU, this.userMeResponse.accessGroup);
    AppSettings.USER = results.user;

    if (results.user.userDetails !== null) {
      this.storageService.setItem(AppSettings.CURRENT_USER_CORPORATE_ID, results.user.userDetails.corporateId);
    }
  }

  setTagList() {
    this.userCommonService.getTagsListApi().subscribe(result => {
      this.storageService.setItem(AppSettings.TAG_DATA, result);
    }, (error) => {
    });
  }

}

